<template>
  <v-card flat class="fill-height">
    <v-card-title class="d-flex align-center justify-space-between">
      <span v-if="!client" class="primary--text">BrightLocal </span>
      <span v-else class="primary--text">
        BrightLocal > {{ client.props['company-name'] }}
      </span>
      <div class="d-flex align-center justify-end">
        <v-btn
          @click="setting_dialog = true"
          class="px-5 text-none"
          color="primary"
          depressed
        >
          Settings
        </v-btn>
      </div>
    </v-card-title>
    <Empty
      v-if="!hasCompletedConfiguration"
      headline="BrightLocal Integration not yet setup!"
    >
      <template v-slot:extra>
        <v-btn
          @click="setting_dialog = true"
          color="primary"
          depressed
          class="text-none px-5"
        >
          Setup BrightLocal Integration
        </v-btn>
      </template>
    </Empty>
    <v-card-text v-else>
      <v-sheet>
        <v-row dense align="start" justify="start">
          <v-col
            cols="12"
            md="6"
            sm="12"
            v-for="(card, index) in cards"
            :key="card.slug"
          >
            <component
              :card="card"
              :is="card.component"
              :key="`card-${index}`"
            ></component>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>

    <BrightLocalSettings
      :current-tool="brightlocal"
      :current-client="client"
      v-model="setting_dialog"
      :project="project_id"
    ></BrightLocalSettings>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BrightLocalSettings from '../components/BrightLocalSettings.vue'
import ReputationManagerCard from '../components/cards/ReputationManagerCard.vue'
import CitationTrackerCard from '../components/cards/CitationTrackerCard.vue'
import GoogleRankingCard from '../components/cards/GoogleRankingCard.vue'
import GoogleMyBusinessCard from '../components/cards/GoogleMyBusinessCard.vue'
import GoogleAnalyticsCard from '../components/cards/GoogleAnalyticsCard.vue'
export default {
  components: {
    BrightLocalSettings,
    ReputationManagerCard,
    CitationTrackerCard,
    GoogleRankingCard,
    GoogleMyBusinessCard,
    GoogleAnalyticsCard
  },
  name: 'BrightLocalTab',
  data: () => ({
    setting_dialog: false
  }),
  created() {
    if (this.project_id) this.fetchBrightLocal(this.project_id)
  },
  computed: {
    ...mapGetters('brightlocal', ['brightlocal', 'client', 'cards']),
    project_id() {
      return this.$route.params.id || null
    },
    hasCompletedConfiguration() {
      return this.brightlocal && this.client ? true : false
    }
  },
  methods: {
    ...mapActions('brightlocal', ['fetchBrightLocal'])
  }
}
</script>

<style></style>
