<template>
  <Dashcard
    :card="card"
    outlined
    :card-height="400"
    :loading="false"
    :has-expand="false"
    :has-delete="false"
  >
    <template v-slot:toolbar></template>
    <template v-slot:default>
      <v-sheet color="#aeabb957" class="fill-height"></v-sheet>
    </template>
  </Dashcard>
</template>

<script>
import Dashcard from '@/modules/DashboardContent/components/Dashcard.vue'
export default {
  components: { Dashcard },
  props: { card: Object }
}
</script>

<style></style>
