<template>
  <CustomDialog
    v-model="open"
    fullscreen
    :has-footer="false"
    :title="isEdit ? 'Update Settings' : 'Save Settings'"
  >
    <template v-slot:content>
      <v-stepper
        class="mx-auto"
        v-model="stepper"
        vertical
        flat
        max-width="900"
      >
        <v-stepper-step class="primary--text" :complete="stepper > 1" step="1">
          <label> Enter BrightLocal API Credentials </label>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card flat class="mb-1">
            <small>
              How to get my API credentials? Click
              <a
                href="https://tools.brightlocal.com/seo-tools/admin/api#authentication"
                target="_blank"
              >
                here
              </a>
            </small>
            <br />
            <v-form v-model="step1_valid">
              <label class="primary--text subtitle-2">
                BrightLocal API Key
              </label>
              <v-text-field
                filled
                outlined
                dense
                clearable
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="api_key"
              ></v-text-field>
              <label class="primary--text subtitle-2">
                BrightLocal API Secret
              </label>
              <v-text-field
                filled
                outlined
                dense
                clearable
                :rules="[requiredRule()]"
                hide-details="auto"
                v-model="api_secret"
              ></v-text-field>
            </v-form>
          </v-card>
          <div class="d-flex align-center justify-end">
            <v-btn
              :disabled="!step1_valid"
              small
              :loading="processing"
              depressed
              class="text-none"
              color="primary"
              @click="handleCreateApi"
            >
              Next Step
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step class="primary--text" :complete="stepper > 2" step="2">
          Select BrightLocal client or use existing BrightLocal client
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card flat min-height="200" class="mb-1">
            <div class="mt-2 d-flex align-center justify-space-between">
              <label class="subtitle-2 primary--text">
                <span v-if="!createMode">Search for BrightLocal Client</span>
              </label>
              <v-btn
                @click="toggleCreateMode"
                color="primary"
                text
                class="subtitle-2 text-none"
              >
                <span v-if="!createMode">Create New Client</span>
                <span v-else>Cancel</span>
              </v-btn>
            </div>
            <div v-if="createMode" class="">
              <v-form v-model="client_valid">
                <label class="subtitle-2 primary--text">
                  Client/Business Name
                </label>
                <v-text-field
                  v-model="client_name"
                  outlined
                  dense
                  :rules="[requiredRule()]"
                  clearable
                  class="my-2"
                  hide-details="auto"
                  placeholder="Enter BrightLocal Client"
                ></v-text-field>
                <label class="subtitle-2 primary--text">Business URL</label>
                <v-text-field
                  v-model="client_url"
                  outlined
                  dense
                  :rules="[requiredRule()]"
                  clearable
                  class="my-2"
                  hide-details="auto"
                  placeholder="my-business.com"
                ></v-text-field>
                <div class="d-flex align-center justify-center">
                  <v-btn
                    depressed
                    small
                    @click="handleCreateClient"
                    color="primary"
                    :disabled="!client_valid"
                    class="text-none px-5"
                  >
                    Save Client
                  </v-btn>
                </div>
              </v-form>
            </div>
            <div
              v-if="!createMode"
              class="d-flex align-center justify-space-between"
            >
              <v-text-field
                v-model="search"
                outlined
                dense
                :disabled="processing"
                clearable
                class="my-2"
                hide-details="auto"
                placeholder="Start typing to Search for BrightLocal Client"
                prepend-inner-icon="mdi-database-search"
              ></v-text-field>
              <v-btn
                @click="handleSearch"
                :disabled="!search || processing"
                depressed
                :loading="processing"
                color="primary"
                class="ml-1 text-none"
              >
                Search
              </v-btn>
            </div>
            <v-list dense v-if="!createMode && clients.length">
              <v-list-item-group
                v-model="selected_client"
                mandatory
                color="primary"
              >
                <v-list-item
                  v-for="client in clients"
                  :key="client['client-id']"
                  :value="client"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      class="subtitle-2"
                      v-text="client['company-name']"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <div class="d-flex align-center justify-space-between">
            <v-btn
              small
              depressed
              class="text-none"
              color="primary"
              @click="stepper = 1"
            >
              Previous Step
            </v-btn>
            <v-btn
              small
              :disabled="!selected_client"
              depressed
              class="text-none"
              color="primary"
              @click="stepper = 3"
            >
              Next Step
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step
          class="primary--text"
          :complete="stepper > 3 && selected_client"
          step="3"
        >
          {{ isEdit ? 'Update' : 'Save' }}
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card color="secondary" class="mb-2" dark v-if="selected_client">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="selected_client['company-name']"
                ></v-card-title>
                <v-card-subtitle
                  v-text="selected_client['client-reference']"
                ></v-card-subtitle>
                <v-card-subtitle>
                  BLID: {{ selected_client['client-id'] }}
                </v-card-subtitle>
              </div>
            </div>
          </v-card>
          <div class="d-flex align-center justify-space-between">
            <v-btn
              small
              depressed
              class="text-none"
              color="primary"
              @click="stepper = 2"
            >
              Previous Step
            </v-btn>
            <v-btn
              small
              @click="handleSave"
              depressed
              class="text-none"
              min-width="100"
              color="primary"
            >
              {{ isEdit ? 'Update' : 'Save' }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: { CustomDialog },
  props: {
    value: { type: Boolean, default: false },
    project: { type: [String, Number], required: true },
    currentTool: { type: Object },
    currentClient: { type: Object }
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val
        if (val) this.set_processing(false)
      },
      immediate: true
    },
    open: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    createMode: {
      handler: function (val) {
        if (val) {
          this.selected_client = null
          this.client_name = null
          this.client_url = null
        } else {
        }
      },
      immediate: true
    },
    currentTool: {
      handler: function (val) {
        this.mapItem(val)
      },
      immediate: true,
      deep: true
    },
    currentClient: {
      handler: function (val) {
        if (val) this.add_client(val.props)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('brightlocal', ['processing', 'clients']),
    isEdit() {
      return this.currentTool ? true : false
    },
    payload() {
      return {}
    },
    api_key: {
      get() {
        return this.$store.getters['brightlocal/api_key']
      },
      set(val) {
        this.$store.commit('brightlocal/set_api_key', val)
      }
    },
    api_secret: {
      get() {
        return this.$store.getters['brightlocal/api_secret']
      },
      set(val) {
        this.$store.commit('brightlocal/set_api_secret', val)
      }
    }
  },
  data: () => ({
    open: false,
    stepper: 1,
    search: null,
    selected_client: null,
    step1_valid: false,
    client_valid: false,
    client_name: null,
    client_url: null,
    createMode: false
  }),
  methods: {
    ...mapActions('brightlocal', [
      'createApi',
      'createClient',
      'searchClients',
      'attachClient'
    ]),
    ...mapMutations('brightlocal', ['add_client', 'set_processing']),
    handleCreateApi() {
      this.createApi({
        payload: {
          api_key: this.api_key,
          api_secret: this.api_secret,
          project_id: this.project
        },
        cb: () => {
          this.stepper = 2
        }
      })
    },
    handleSearch() {
      if (this.processing) return
      this.searchClients(this.search)
    },
    handleCreateClient() {
      if (!this.client_valid) return
      this.createClient({
        payload: {
          client_name: this.client_name,
          client_url: this.client_url
        },
        cb: () => {
          this.client_name = null
          this.client_url = null
          this.createMode = false
        }
      })
    },
    handleSave() {
      this.attachClient({
        client_id: this.selected_client['client-id'] || null,
        cb: () => {
          this.open = false
          this.appSnackbar('BrightLocal configuration updated.')
        }
      })
    },
    toggleCreateMode() {
      this.createMode = !this.createMode
    },
    mapItem(item) {
      if (item) {
        this.$store.commit('brightlocal/set_api_key', item.api_key)
        this.$store.commit('brightlocal/set_api_secret', item.api_secret)
        this.selected_client = this.$store.getters['brightlocal/client']
      }
    }
  }
}
</script>

<style></style>
