<template>
  <v-card flat class="fill-height">
    <v-card-title class="d-flex align-center justify-space-between">
      <span class="primary--text">SEOProfiler</span>
      <div class="d-flex align-center justify-end">
        <v-btn disabled class="px-5 text-none" color="primary" depressed>
          Settings
        </v-btn>
      </div>
    </v-card-title>
    <Empty icon="mdi-tools" headline="Coming Soon..."></Empty>
  </v-card>
</template>

<script>
export default {
  name: 'SEOProfilerTab'
}
</script>

<style></style>
