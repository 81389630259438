<template>
  <v-card min-height="100vh" class="rounded-10" outlined flat>
    <v-card-title v-if="tooltabs.length > 1">
      <v-tabs
        class="files-menu-tab"
        height="50px"
        v-model="active_tab"
        :show-arrows="mdAndDown"
        dense
        hide-slider
        active-class="active-files-menu"
      >
        <v-tab
          v-for="tab in tooltabs"
          :href="`#${tab.value}`"
          @click="changeTab(tab)"
          exact
          :key="tab.name"
          class="text-none"
        >
          <div>
            <v-avatar tile class="mr-3" size="20">
              <v-img contain :src="tab.icon"></v-img>
            </v-avatar>
            <span class="files-menu-button">{{ tab.name }}</span>
          </div>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <BrightLocalTab v-if="active_tab === 'brightlocal'"></BrightLocalTab>
    <SEOProfilerTab v-else-if="active_tab === 'seoprofiler'"></SEOProfilerTab>
  </v-card>
</template>
<script>
import BrightLocalTab from './Tabs/BrightLocalTab.vue'
import SEOProfilerTab from './Tabs/SEOProfilerTab.vue'
export default {
  name: 'BrightLocal',
  components: { BrightLocalTab, SEOProfilerTab },
  data: () => ({
    active_tab: 'brightlocal',
    tooltabs: [
      {
        name: 'BrightLocal',
        value: 'brightlocal',
        icon: require('@/assets/appIcons/brightlocal-favicon.png')
      },
      {
        name: 'SEOProfiler',
        value: 'seoprofiler',
        icon: require('@/assets/appIcons/seoprofiler-icon.png')
      }
    ]
  }),
  watch: {
    '$route.params.source': {
      handler: function (val) {
        this.active_tab = val ? val : 'brightlocal'
      },
      immediate: true
    }
  },
  methods: {
    changeTab(tab) {
      this.$router.push({
        name: 'project-preview-tools',
        params: { source: tab.value }
      })
    }
  }
}
</script>
<style lang="scss"></style>
